import { ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import { Alert, Button, Descriptions, DescriptionsProps, Space, Typography } from 'antd';
import { H } from 'highlight.run';
import { ReactElement } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import environmentConfig from '../environmentConfig';
import { ComponentErrorBoundaryProps, ErrorBoundaryInfoContextValue } from './ComponentErrorBoundary';

const { localDevelopmentMode } = environmentConfig;

const logErrorsInLocalDevelopment = false;

export type ErrorFallbackProps = ComponentErrorBoundaryProps & FallbackProps & {
  contextInfo: ErrorBoundaryInfoContextValue['info'];
};

export default function ErrorFallback({ error, resetErrorBoundary, componentNameTKey, contextInfo, displayInfo = [], allowPageRefresh }: ErrorFallbackProps): ReactElement {
  const { t } = useTranslation();

  usePureEffect(
    (deps) => {
      if (!localDevelopmentMode || logErrorsInLocalDevelopment) {
        const wasRecordingBefore = H.getRecordingState() === 'Recording';

        if (!wasRecordingBefore)
          H.start({ forceNew: true });

        const payload = {
          'Component name': deps.componentNameTKey ? deps.t(deps.componentNameTKey, { lng: 'en-US' }) : 'unspecified',
          ...Object.map(deps.contextInfo, ([key, value]) => [key, value?.toString() ?? 'NULL']),
        };

        H.consumeError(deps.error, undefined, payload);
        // eslint-disable-next-line no-console
        console.log('Error has been automatically reported to Highlight.', { error: deps.error, payload });

        if (!wasRecordingBefore)
          H.stop();
      }
    },
    [],
    { error, componentNameTKey, contextInfo, displayInfo, t },
  );

  const descriptionItems = displayInfo
    ? displayInfo.map<NonNullable<DescriptionsProps['items']>[0]>(({ key, label, value }) => ({ key, label, children: value ?? <em>null</em> }))
    : null;

  return (
    <ErrorFallback.Box>
      <Typography.Title level={3} style={{ margin: 0, textAlign: 'center' }}>
        {t('errorHandling.anErrorOccurredInTheXComponent', { value: componentNameTKey ? t(componentNameTKey) : t('errorHandling.child') })}
      </Typography.Title>
      {
        descriptionItems &&
        <ErrorFallback.Descriptions
          size="small"
          column={1}
          items={descriptionItems}
        />
      }
      {
        error instanceof Error &&
        <ErrorFallback.Alert
          type="error"
          message={error.stack}
        />
      }
      <Space>
        <Button type="primary" icon={<SyncOutlined />} onClick={resetErrorBoundary}>{t('errorHandling.reloadComponent')}</Button>
        {
          allowPageRefresh &&
          <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>{t('errorHandling.refreshPage')}</Button>
        }
      </Space>
    </ErrorFallback.Box>
  );
}

ErrorFallback.Box = styled.div`
  width: 100%;
  height: 100%;
  padding: ${p => p.theme.paddingSM}px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.paddingSM}px;
`;

ErrorFallback.Alert = styled(Alert)`
  max-width: 100%;
  flex: 0 1 auto;
  min-height: 0;

  .ant-alert-message {
    max-height: 250px;
    overflow: auto;
    font-family: ${p => p.theme.fontFamilyCode};
    white-space: pre-wrap;
  }
`;

ErrorFallback.Descriptions = styled(Descriptions)`
  && {
    max-width: minmax(100%, 400px);

    table {
      width: unset;
    }

    .ant-descriptions-item {
      padding-bottom: 0;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      flex: 0 0 auto;
      min-width: 0;
    }

    .ant-descriptions-item-content {
      cursor: default;
      font-family: ${p => p.theme.fontFamilyCode};
    }
  }
`;